import React, { Component } from 'react'
import { graphql } from 'gatsby'
// import _ from 'lodash'
// import { head as _head } from 'lodash'

import {
    Row, 
    Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import Layout from '../components/layout'
import NewsList from '../components/news/news_list'


// if (typeof window !== `undefined`) {
//   window.newsPostsToShow = 4
// }

class CodexPage extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            postsToShow:4,
            postsToLoad:2,
        }
    }

    componentDidMount() {
        window.addEventListener(`scroll`, this._handleScroll)
        let mq = document.getElementsByClassName('wrapper')[0].offsetWidth
        // console.log(`news-mount - mq = ${mq}`)
        if (mq>1138) this.setState({ postsToShow: 8, postsToLoad: 4 })
        else if (mq>718) this.setState({ postsToShow: 6, postsToLoad: 3 })
        else this.setState({ postsToShow: 4, postsToLoad: 2 })
    }

    componentWillUnmount() {
        window.removeEventListener(`scroll`, this._handleScroll)
    }

    _update() {
        //     // console.log(`news-update`)
        const distanceToBottom = document.querySelector('#footer .base').offsetTop
        if (distanceToBottom < window.scrollY+window.innerHeight  && this.state.postsToShow < this.props.data.typeCodex.totalCount) {
            console.log(`d to btm: ${distanceToBottom} < w.sY: ${window.scrollY} / ${window.scrollY+window.innerHeight}  && pts: ${this.state.postsToShow} < ${this.props.data.typeCodex.totalCount} `)
            this.setState({ postsToShow: this.state.postsToShow + this.state.postsToLoad })
        }
        this.ticking = false
    }

    _handleScroll = () => {
        // console.log(`news-scrollhandle`)
        if (!this.ticking) {
            this.ticking = true
            requestAnimationFrame(() => this._update())
        }
    }


    render() {
        // console.log(`this.props.data = `, this.props.data)
        // const news = this.state.allLocaleData
        const news = this.props.data.typeCodex.edges.map(e => e.node)
        const enNews = this.props.data.allNewsEngExclusive.edges.map(e => e.node)
        // const lang = this.props.lng


        // DEBUG :
        // console.log(` ==== this.props : news list page ==== `, this.props )
        // console.log(`news = `, news)
        // console.log(`enNews = `, enNews)
        // console.log(`this.state.postsToShow = `, this.state.postsToShow)
        
        return (
            <NamespacesConsumer>

            {(t, { i18n }) => {

                return (
                    <Layout siteData={ { title:`${t("MU000")} | ${t("MU006")}`, url:this.props.location.href } }>
                        <div id="CodexList" className="body-page ">
                            <nav role="navigation">
                                <Container className="wrapper line-head">
                                    <div className="phead">{t('MU006')}</div>
                                </Container>
                            </nav>

                            <main role="main" className="main">
                                <Container className="wrapper">
                                {
                                    (news) ?
                                        (news.length>0)?
                                            <Row className="plist plistsquare d-flex justify-content-start flex-wrap">
                                            {news.map((newsPost, i) => {
                                                // console.log(`i = ${i} pts=${this.state.postsToShow} `)
                                                let hs = (i<this.state.postsToShow) ? ' show ' : 'hidden'
                                                return (
                                                    <NewsList key={`codexlist-card-${i}`} news={newsPost} enNews={enNews} hs={hs}/>
                                                )
                                            })}
                                            </Row>
                                            :
                                            <Row className="error msg-block"> {t("ER002")} </Row>
                                        :
                                        <Row className="loading msg-block"></Row>
                                }
                                </Container>
                            </main>
                        </div>

                    </Layout>
                )
            }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(CodexPage)

export const query = graphql`
query getCodexPageLang($lng: String!, $lngReg: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
        ...LocaleFragment
    }

    typeCodex: allPrismicNews( filter: { lang: { regex: $lngReg }, data: {type: { eq: "codex" }} }, sort: { fields: data___datetime, order: DESC }) {
        edges {
            node {
                ... on PrismicNews {
                    id: prismicId
                    lang
                    alternate_languages {
                        id
                        lang
                    }
                    head: data {
                        datetime
                        title {
                            text
                        }
                        thumbnail {
                            url
                        }
                        type
                    }
                    raw: dataString
                }
            }
        }
        totalCount
    }

    allNewsEngExclusive: allPrismicNews(filter: { lang: { eq: "en-gb" }, data: {type: { eq: "codex" }} }, sort: { fields: data___datetime, order: DESC }) {
        edges {
            node {
                id: prismicId
                data { title { text } }
            }
        }
    }
}
`
